<template>
    <AuthSection
        :allows-sso-account-creation="props.allowsSsoAccountCreation"
        :compact="compact"
        :t="props.t"
        :apple-credentials="props.appleCredentials"
        :facebook-credentials="props.facebookCredentials"
        :google-credentials="props.googleCredentials"
        :error="state.error"
        :is-webview="isWebviewBrowser()"
        @successful="beforeLoginSuccessful"
        @login-started="disableForm"
        @failure="beforeLoginFailure"
    >
        <template #form>
            <LoginFormHandler
                remember-me
                :t="props.t"
                :recaptcha-key="props.recaptchaKey"
                :forgot-password-href="props.forgotPasswordHref"
                :disabled="state.disabled"
                :is-webview="isWebviewBrowser()"
                @successful="beforeLoginSuccessful"
                @failure="(error) => state.error = error"
                @reset-error="() => state.error = ''"
            />
        </template>
        <template
            v-if="props.showSignUpUrl"
            #footer
        >
            <p class="z-b2 auth-section__footer-container__sign-up-text">
                {{ props.t.newToZumbaText }}
            </p>
            <a
                :href="props.signUpHref"
                class="z-b2 auth-section__footer-container__sign-up-link"
            >
                {{ props.t.signUpText }}
            </a>
        </template>
    </AuthSection>
</template>

<script lang="ts" setup>
import AuthSection from "@components/Login/AuthSection.vue";
import LoginFormHandler from "@components/Login/Form/LoginFormHandler.vue";
import {PropType, reactive} from "vue";
import {FacebookSdkOptions} from "@ts/Login/facebook_service";
import {AppleInitOptions} from "@ts/Login/apple_service";
import {GoogleInitOptions} from "@ts/Login/google_service";
import { isWebview } from '@ts/Util/responsiveness'

const props = defineProps({
    recaptchaKey: {
        type: String,
        default: ''
    },
    compact: {
        type: Boolean,
        default: false,
    },
    showSignUpUrl: {
        type: Boolean,
        default: false
    },
    allowsSsoAccountCreation: {
        type: Boolean,
        default: false
    },
    appleCredentials: {
        type: Object as PropType<AppleInitOptions>,
        required: true
    },
    facebookCredentials: {
        type: Object as PropType<FacebookSdkOptions>,
        required: true
    },
    googleCredentials: {
        type: Object as PropType<GoogleInitOptions>,
        required: true
    },
    signUpHref: {
        type: String,
        default: '/user/signup'
    },
    forgotPasswordHref: {
        type: String,
        default: '/user/forgot_password'
    },
    webviewLogin: {
        type: Boolean,
        default: false
    },
    t: {
        type: Object,
        default: () => ({
            title: 'Log in',
            subtitle: 'Log in using one of the options below',
            emailLabel: 'Email',
            passwordLabel: 'Password',
            rememberLabel: 'Remember Me',
            forgotPasswordText: 'Forgot Password?',
            loginViaEmailText: 'Log in via email link',
            loginText: 'Log In',
            useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
            abcCapitalized: 'ABC',
            abcLowerCase: 'abc',
            numeric: '123',
            symbols: '!@%',
            show: 'Show',
            hide: 'Hide',
            newToZumbaText: 'New to zumba?',
            signUpText: 'Sign Up',
        })
    },
})

const emit = defineEmits(['successful', 'failure'])

const state = reactive({
    error: '',
    disabled: false,
})

const disableForm = () => state.disabled = true

const enableForm = () => state.disabled = false

const beforeLoginSuccessful = (event) => emit('successful', event)

const beforeLoginFailure = (event) => {
    enableForm()
    state.error = event.error || event.message

    emit('failure', event)
}

const isWebviewBrowser = () => props.webviewLogin && isWebview()

</script>

<style lang="scss" scoped>

.theme-zumba .auth-section__footer-container__sign-up-link {
    color: var(--zumba-plum);
    font-family: 'Inter';
}

</style>
