<template>
    <div class="input-password">
        <template v-if="showRequirements">
            <PasswordRequirements
                v-if="passwordValue"
                v-show="!state.isPasswordValid"
                :t="props.t"
                arrow-position="bottom-left"
                :value="passwordValue"
            />
        </template>

        <ZInput
            v-model="passwordValue"
            :name="name"
            :type="type"
            :label="label"
            :show-label="showLabel"
            :disabled="disabled"
            :required="required"
            :placeholder="placeholder"
            :maxlength="maxlength"
            :max="max"
            :min="min"
            :show-required="showRequired"
            :autocomplete="autocomplete"
            @input="onInput"
            @focus="(event) => emit('focus', event)"
            @blur="(event) => emit('blur', event)"
            @invalid="(event) => emit('invalid', event)"
        >
            <template
                v-if="showToggle || showRequirements"
                #right-slot
            >
                <div class="input-password__input-right-slot">
                    <template v-if="showRequirements">
                        <SVGCheckRounded
                            :class="{
                                'input-password__input-check': true,
                                'input-password__input-check__active': state.isPasswordValid,
                            }"
                        />
                    </template>
                    <template v-if="showToggle">
                        <span
                            class="input-password__password-toggle"
                            @click="toggleView"
                        >
                            {{ toggleLabel }}
                        </span>
                    </template>
                </div>
            </template>
        </ZInput>

        <small
            v-if="error"
            class="input-password__error"
        >
            {{ error }}
        </small>

        <small
            v-if="props.t.hint && !error"
            class="input-password__hint"
        >
            {{ props.t.hint }}
        </small>
    </div>
</template>

<script lang="ts" setup>
import ZInput from '../Core/ZInput.vue'
import {PropType, ref, watch, computed, reactive} from 'vue'
import PasswordRequirements from "@components/Core/PasswordRequirements.vue";
import SVGCheckRounded from '@icons/check-rounded.svg'
import {createAuthService} from "@ts/Login/auth_service";

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
            abcCapitalized: 'ABC',
            abcLowerCase: 'abc',
            numeric: '123',
            symbols: '!@%',
            show: 'Show',
            hide: 'Hide',
            hint: '',
        })
    },
    label: {
        type: String,
        default: "Choose password"
    },
    showLabel: {
        type: Boolean,
        default: true
    },
    name: {
        type: String,
        default: 'password'
    },
    modelValue: {
        type: [ String, Number ],
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    maxlength: {
        type: Number as PropType<number | null>,
        default: null
    },
    max: {
        type: [Number, String],
        default: null,
    },
    min: {
        type: [Number, String],
        default: null,
    },
    error: {
        type: String,
        default: ''
    },
    showRequired: {
        type: Boolean,
        default: false
    },
    showToggle: {
        type: Boolean,
        default: true
    },
    showRequirements: {
        type: Boolean,
        default: false
    },
    autocomplete: {
        type: String,
        default: 'on'
    }
})

const passwordValue = ref(<string>props.modelValue)

const authService = createAuthService()

const emit = defineEmits(['invalid', 'update:model-value', 'focus', 'blur'])

const onInput = (event) => {
    passwordValue.value = event.target.value

    emit('update:model-value', passwordValue.value)
}

const type = computed(() => state.isPasswordHide ? 'password' : 'text')

const toggleLabel = computed(() => state.isPasswordHide ? props.t.show : props.t.hide)

const state = reactive({
    isPasswordValid: false,
    isPasswordHide: true
})

const toggleView = () => state.isPasswordHide = !state.isPasswordHide

watch(() => passwordValue.value, (newPassword) => {
    state.isPasswordValid = authService.isPasswordValid(newPassword)
}, { immediate: true } )
</script>

<style scoped lang="scss">
.input-password {

    &__error {
        display: block;
        font-size: .75rem;
        font-style: italic;
        font-weight: 400;
        padding: 0.375rem 0.5625rem 0.5625rem;
        background: #f04124;
        color: #fff;
    }

    &__input-right-slot {
        margin-top: -0.3rem;
        display: flex;
        align-items: center;
    }

    &__password-toggle {
        display: block;
        font-weight: bold;
        font-size: .75rem;
        color: var(--zumba-dark-coral);
        cursor: pointer;
        text-transform: uppercase;
        line-height: 2rem;
        margin-left: 0.75rem;
    }

    &__hint {
        color: var(--zumba-gray-400);
    }

    &__input-check {
        width: 1.75rem;
        height: 1.75rem;

        &:deep(circle) {
            fill: var(--zumba-gray-100);
        }

        &:deep(path) {
            stroke: var(--zumba-gray-400);
        }

        &__active:deep(circle) {
            fill: var(--zumba-neon-green);
        }

        &__active:deep(path) {
            stroke: var(--zumba-gray-700);
        }
    }
}

.theme-zumba .input-password {
    &__password-toggle {
        color: var(--zumba-plum);
    }
}
</style>
