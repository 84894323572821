<template>
    <ZButton
        :rounded="props.rounded"
        :variety="props.variety"
        :mode="props.mode"
        :wide="wide"
        class="facebook-login"
        @click.prevent="$emit('click')"
    >
        <template #icon>
            <FacebookSVG class="facebook-icon" />
        </template>
    </ZButton>
</template>

<script setup lang="ts">
import ZButton from "@components/Core/ZButton.vue"
import FacebookSVG from '@icons/social/fb.svg';

defineEmits(['click'])
const props = defineProps({
    variety: {
        type: String,
        default: 'cyclops',
        validator: (value: string) => {
            // You can add more variety types here
            return [
                'optimus',
                'robin',
                'phoenix',
                'cyclops',
                'rogue',
                'love',
            ].includes(value)
        },
    },
    mode: {
        type: String,
        default: 'light',
        validator: (value: string) => {
            // You can add more modes types here
            return [
                'light',
                'dark',
            ].includes(value)
        },
    },
    wide: {
        type: String,
        default: 'never',
        validator: (value: string) => {
            return [
                'never',
                'always',
                'mobile-only',
                'desktop-only',
            ].includes(value)
        },
    },
    rounded: {
        type: String,
        default: 'always',
        validator: (value: string) => {
            return [
                'never',
                'always',
                'mobile-only',
                'desktop-only',
            ].includes(value)
        },
    },
})
</script>

<style scoped lang="scss">
.facebook-icon:deep(path) {
    fill: var(--zumba-gray-800);
}

.facebook-login {
    :hover {
        .facebook-icon:deep(path) {
            fill: var(--zumba-white);
        }
    }
}
</style>
