<template>
    <section :class="classes">
        <h1 class="z-h4 auth-section__title">
            {{ props.t.title }}
        </h1>
        <p
            v-if="!isWebview"
            class="z-b3 auth-section__text"
            :class="{'left-aligned': props.leftAligned}"
        >
            {{ props.t.subtitle }}
        </p>

        <div
            v-if="!isWebview"
            class="auth-section__icons"
            :class="{'left-aligned': props.leftAligned}"
        >
            <GoogleHandler
                class="auth-section__icons__google-icon"
                :client-id="props.googleCredentials.clientID"
                :allows-account-creation="props.allowsSsoAccountCreation"
                @successful="beforeAuthenticationSuccessful"
                @failure="beforeAuthenticationFailure"
                @login-started="beforeAuthenticationStart"
                @click="handleAuthenticationClick('google')"
            />
            <FacebookHandler
                class="auth-section__icons__facebook-icon"
                :app-id="props.facebookCredentials.appId"
                :allows-account-creation="props.allowsSsoAccountCreation"
                @successful="beforeAuthenticationSuccessful"
                @failure="beforeAuthenticationFailure"
                @login-started="beforeAuthenticationStart"
                @click="handleAuthenticationClick('facebook')"
            />
            <AppleHandler
                class="auth-section__icons__apple-icon"
                :client-id="props.appleCredentials.clientID"
                :redirect-uri="props.appleCredentials.redirectURI"
                :allows-account-creation="props.allowsSsoAccountCreation"
                @successful="beforeAuthenticationSuccessful"
                @failure="beforeAuthenticationFailure"
                @login-started="beforeAuthenticationStart"
                @click="handleAuthenticationClick('apple')"
            />

            <slot name="header-buttons" />
        </div>

        <div
            v-if="props.error"
            class="z-b3 error-component"
        >
            <!-- eslint-disable vue/no-v-html-->
            <span
                class="error-component__text"
                v-html="props.error"
            />
        </div>

        <h2
            class="auth-section__container"
            :class="{'left-aligned': props.leftAligned}"
        >
            <span
                v-if="!isWebview"
                class="z-b3 auth-section__text"
                :class="{'left-aligned': props.leftAligned}"
            >
                {{ props.t.loginWithText }}
            </span>
        </h2>

        <div class="auth-section__form-container">
            <slot name="form" />
        </div>

        <div class="auth-section__footer-container">
            <slot name="footer" />
        </div>
    </section>
</template>

<script lang="ts" setup>
import FacebookHandler from "@components/Login/Facebook/FacebookHandler.vue";
import AppleHandler from "@components/Login/Apple/AppleHandler.vue";
import {computed, PropType, ref} from "vue";
import {FacebookSdkOptions} from "@ts/Login/facebook_service";
import {AppleInitOptions} from "@ts/Login/apple_service";
import {GoogleInitOptions} from "@ts/Login/google_service";
import GoogleHandler from "@components/Login/Google/GoogleHandler.vue";
import { mixpanelServiceTrack } from '@ts/Util/analytics';

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            title: '',
            subtitle: '',
            loginWithText: '',
        })
    },
    appleCredentials: {
        type: Object as PropType<AppleInitOptions>,
        required: true
    },
    facebookCredentials: {
        type: Object as PropType<FacebookSdkOptions>,
        required: true
    },
    googleCredentials: {
        type: Object as PropType<GoogleInitOptions>,
        required: true
    },
    allowsSsoAccountCreation: {
        type: Boolean,
        default: false
    },
    error: {
        type: String,
        default: '',
    },
    compact: {
        type: Boolean,
        default: false,
    },
    leftAligned: {
        type: Boolean,
        default: false,
    },
    isWebview: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['successful', 'failure', 'loginStarted'])

const classes = computed(() => ({
    'auth-section': true,
    'auth-section__compact': props.compact,
    'left-aligned': props.leftAligned,
}))

const beforeAuthenticationSuccessful = (event) => emit('successful', event)

const beforeAuthenticationFailure = (event) => emit('failure', event)

const beforeAuthenticationStart = (event) => emit('loginStarted', event)

const started = ref(false);

const handleAuthenticationClick = (provider) => {
    if (!started.value) {
        started.value = true;
        mixpanelServiceTrack('TrackCreateAccountStarted', {
            'createAuthMethod': provider,
        });
    }
};

</script>

<style lang="scss" scoped>
.auth-section {
    padding: 0 0.625rem;
    margin: 2rem auto;

    @media screen and (min-width: 64rem) {
        margin: 7.75rem auto;
    }

    &__compact {
        margin: 2.5rem auto;
    }

    &.left-aligned {
        padding: 0;
        margin: 1.875rem auto 0;
    }

    &__text {
        &.left-aligned {
            text-align: left;
            padding: 0;
            background: none;
        }
    }

    &__icons {
        &.left-aligned {
            justify-content: flex-start;
            margin-bottom: 1.5rem;

            & > * {
                margin: 0 1rem 0 0;
            }
        }
    }

    &__container {
        &.left-aligned {
            text-align: left;
            border: none;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: .2rem;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: var(--zumba-gray-400);
        margin-bottom: 1.25em;
    }

    &__icons > * {
        margin: 0 1rem;
        cursor: pointer;
    }

    &__form {
        margin: auto;
    }

    &__container {
        text-align: center;
        border-bottom: 1px solid var(--zumba-gray-200);
        line-height: 0.1em;
        margin: 1.25rem auto 1.75rem;

        span {
            background: var(--zumba-white);
            padding: 0 10px;
            line-height: 0;
        }
    }

    &__button-login,
    &__signup-container,
    &__icons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icons__facebook-icon,
    &__icons__google-icon,
    &__icons__apple-icon {
        max-width: 3.2rem;
        max-height: 3.2rem;

        &:deep(button) {
            background-color: var(--zumba-white) !important;
        }
    }

    &__icons__facebook-icon {
        &:deep(button span svg path) {
            fill: black !important;
        }
    }
    
    &__icons__apple-icon {
        &:deep(button span svg g path) {
            fill: black !important;
        }
    }
}

.error-component {
    display: flex;
    justify-content: center;
    text-align: center;
    color: var(--zumba-error-red);
    font-weight: 700;
    margin: 2rem 0;

    &__text {
        max-width: 25rem;
        color: var(--zumba-error-red) !important;
    }

    &:deep(&__link) {
        color: var(--zumba-error-red);
        text-decoration: underline;
    }
}
.theme-zumba .error-component {
    &__text {
        font-family: 'Inter'
    }
}

/** Strong */
.theme-strong {
    .auth-section {
        &__title {
            color: var(--zumba-white);
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            line-height: 2.5rem;
            font-size: 1.875rem;
        }

        &__text {
            color: var(--zumba-white);
        }

        &__container,
        &__text {
            background-color: #272a38;
            color: var(--zumba-white);
            text-transform: lowercase;
            font-size: 1rem;
        }
    }
}

@media all and (min-width: 48rem) {}

</style>

<style lang="scss">
.auth-section {
    &__footer-container {
        display: flex;
        justify-content: center;
        align-items: baseline;

        .auth-section__footer-container {
            &__sign-up-text {
                margin: 0 0.5rem 0 0;
                font-family: inherit;
                font-family: 'Inter';
            }

            &__sign-up-link {
                color: var(--zumba-light-pink);
                font-family: inherit;
                font-family: 'Inter';
            }

            &__sign-up-link,
            &__sign-up-text {
                font-weight: 700;
                margin-top: 1.25rem;
            }

            &__sign-up-text {
                font-size: .875rem;
            }

            &__sign-up-link,
            &__sign-up-text {
                font-size: 1.125rem;
            }
        }
    }
}

.theme-strong {
    .auth-section {
        &__footer-container {
            &__sign-up-text {
                color: var(--zumba-white);
            }

            &__sign-up-link {
                color: var(--zumba-white);
                text-decoration: underline;
                font-family: inherit;
                font-family: 'Inter';
            }
        }
    }
}
</style>
