<template>
    <form
        ref="login"
        :class="['login-form__form', {'colored-background': props.coloredBackground}]"
        @submit.prevent="emit('submit', state)"
    >
        <ZInput
            :model-value="state.email"
            :label="props.t.emailLabel"
            :disabled="props.disabled"
            autocomplete="username"
            name="username"
            type="text"
            required
            @update:model-value="(value) => state.email = value"
        />

        <PasswordInput
            :model-value="state.password"
            :t="props.t"
            :label="props.t.passwordLabel"
            :disabled="props.disabled"
            autocomplete="current-password"
            name="password"
            type="password"
            required
            @update:model-value="(value) => state.password = value"
        />

        <div class="login-form__actions-container">
            <FormCheckbox
                v-if="rememberMe"
                :model-value="state.remember"
                :label="props.t.rememberLabel"
                class="login-form__checkbox"
                name="remember"
                :mode="theme === 'strong' ? 'dark' : 'light'"
                @update:model-value="rememberToggle"
            />

            <a
                v-if="isWebview"
                :href="props.forgotPasswordHref"
                class="z-b4 f-inter"
            >
                {{ props.t.forgotPasswordText }}
            </a>
        </div>

        <slot />

        <div v-if="props.buttonAboveForgotPass">
            <ZButton
                class="login-form__button-login button__above"
                variety="optimus"
                wide="never"
                :disabled="props.disabled"
            >
                {{ props.t.loginText }}
            </ZButton>
        </div>

        <div
            v-if="props.forgotPasswordHref && !props.isWebview"
            class="login-form__signup-container"
        >
            <p class="z-b4 login-form__signup-container__forgot-password-text">
                {{ props.t.forgotPasswordText }}
            </p>
            <a
                :href="props.forgotPasswordHref"
                class="z-b4 login-form__signup-container__forgot-password-link"
            >
                {{ props.t.loginViaEmailText }}
            </a>
        </div>

        <div v-if="!props.buttonAboveForgotPass">
            <ZButton
                class="login-form__button-login button__below"
                variety="optimus"
                wide="never"
                :disabled="props.disabled"
            >
                {{ props.t.loginText }}
            </ZButton>
        </div>

        <p
            v-if="props.isWebview"
            class="z-b4 f-inter send-me-a-link"
        >
            {{ props.t.tryingToLogIn }}

            <ZButton
                class="login-form__button-send-me-a-link"
                variety="cyclops"
                wide="never"
                :href="forgotPasswordHref"
            >
                {{ props.t.sendMeALink }}
            </ZButton>
        </p>
    </form>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import ZButton from "@components/Core/ZButton.vue"
import ZInput from '@components/Core/ZInput.vue';
import FormCheckbox from '@components/Core/FormCheckbox.vue';
import PasswordInput from '@components/Login/PasswordInput.vue';
import { theme } from '@ts/Util/theme';

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            emailLabel: 'Email',
            passwordLabel: 'Password',
            rememberLabel: 'Remember Me',
            forgotPasswordText: 'Forgot Password?',
            loginViaEmailText: 'Log in via email link',
            loginText: 'Log In',
            useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
            abcCapitalized: 'ABC',
            abcLowerCase: 'abc',
            numeric: '123',
            symbols: '!@%',
            show: 'Show',
            hide: 'Hide',
            tryingToLogIn: 'Trying to log in using Google, Facebook or Apple?',
            sendMeALink: 'Send me a link',
        })
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    rememberMe: {
        type: Boolean,
        default: false,
    },
    email: {
        type: String,
        default: '',
    },
    forgotPasswordHref: {
        type: String,
        default: '',
    },
    buttonAboveForgotPass: {
        type: Boolean,
        default: false
    },
    isWebview: {
        type: Boolean,
        default: false,
    },
    coloredBackground: {
        type: Boolean,
        default: false
    },
})

const state = reactive({
    remember: false,
    email: props.email ?? '',
    password: '',
})

const emit = defineEmits(['submit'])

const rememberToggle = () => state.remember = !state.remember
</script>

<style lang="scss" scoped>

.zumba-dance {
    text-align: center;
    width: 299px;
    margin:0 auto;
    color: var(--zumba-gray-600);
}

.send-me-a-link {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    width: 250px;
    margin: 0 auto;
    color: var(--zumba-gray-600);
}

.login-form__form > * {
    margin-bottom: 1rem;
}

.login-form__checkbox {
    display: flex;
}

.login-form__actions-container {
    display: flex;
    justify-content: space-between;
}

.login-form__button-login,
.login-form__signup-container,
.login-form__button-send-me-a-link {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.login-form__signup-container {
    margin-bottom: 1rem;
}

.login-form__signup-container__forgot-password-text {
    margin: 0 0.5rem 0 0;
    font-family: inherit;
    font-family: 'Inter';
}

.login-form__signup-container__forgot-password-link {
    color: var(--zumba-dark-coral);
    font-family: 'Inter';
}

.login-section__container {
    text-align: center;
    border-bottom: 1px solid var(--zumba-gray-200);
    line-height: 0.1em;
    margin: 2rem auto;
}

.login-section__container span {
    background: var(--zumba-white);
    padding: 0 10px;
    line-height: 0;
}

.login-section__text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--zumba-gray-400);
}

.g-recaptcha {
    display: flex;
    justify-content: center;
}

.login-form__signup-container__forgot-password-text {
    font-size: .875rem;
}

.colored-background {
    .login-form__signup-container__forgot-password-text {
        color: var(--zumba-gray-400);
    }

    .login-form__signup-container__forgot-password-link {
        color: var(--zumba-white);
        text-decoration: underline;
    }
    .login-form__signup-container__forgot-password-link:hover, a:active, a:focus, a:visited {
        color: var(--zumba-white);
        text-decoration: underline;
    }
}

/** Strong */

.theme-strong {
    .login-form__signup-container__forgot-password-text  {
        color: var(--zumba-white);
    }

    .login-form__signup-container__forgot-password-link {
        color: var(--zumba-white);
        text-decoration: underline;
        font-family: inherit;
        font-family: 'Inter';
    }

    .login-section__container .login-section__text {
        background-color: #272a38;
        color: var(--zumba-white);
        text-transform: lowercase;
        font-size: 1rem;
    }

    .login-section__container {
        margin: 1rem 0 2rem auto;
    }

    .zumba-dance {
        color: var(--zumba-white);
    }
}

@media all and (min-width: 48rem) {
    .zumba-dance {
        width: 399px;
    }
}
</style>
