<template>
    <div
        :class="{
            'input-formula': true,
            'input-formula__arrow': hasArrow,
            [`input-formula__arrow__${props.arrowPosition}`]: hasArrow,
        }"
    >
        <p class="input-formula__suggestion">
            {{ props.t.useAtLeastTwo }}
        </p>
        <ul class="input-formula__list">
            <li
                v-for="({ label, meets }, index) in requirements"
                :key="`label-${index}`"
                :class="{
                    'input-formula__list__item': true,
                    'input-formula__met-requirement': meets
                }"
            >
                {{ label }}
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import {computed, PropType} from "vue";
import {REGEX_PASSWORD_COMBINATIONS} from "@ts/Login/auth_service";

const {
    upperCase,
    lowerCase,
    numeric,
    symbol
} = REGEX_PASSWORD_COMBINATIONS;

const props = defineProps({
    value: {
        type: String,
        required: true,
    },
    t: {
        type: Object,
        default: () => ({
            useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
            abcCapitalized: 'ABC',
            abcLowerCase: 'abc',
            numeric: '123',
            symbols: '!@%'
        })
    },
    arrowPosition: {
        type: String as PropType<'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'none'>,
        default: () => 'none'
    },
})

const createRequirement = (label: string , meets: boolean) => ({ label, meets })

const requirements = computed(() => [
    createRequirement(props.t.abcCapitalized, upperCase.test(props.value)),
    createRequirement(props.t.abcLowerCase, lowerCase.test(props.value)),
    createRequirement(props.t.numeric, numeric.test(props.value)),
    createRequirement(props.t.symbols, symbol.test(props.value)),
])

const hasArrow = computed(() => props.arrowPosition !== 'none')
</script>

<style scoped lang="scss">
.input-formula {
    background: var(--zumba-gray-600);
    padding: 1.25em;
    margin: 0 0 1.5em;
    position: relative;
    color: var(--zumba-white);

    &__arrow {
        --default-pixel: 12px;

        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: var(--default-pixel) solid transparent;
            border-right: var(--default-pixel) solid transparent;
            border-top: var(--default-pixel) solid var(--zumba-gray-600);
            position: absolute;
        }

        /* TOP LEFT/RIGHT */
        &__top-left:before,
        &__top-right:before {
            top: calc(var(--default-pixel) * -1);
            transform: rotate(180deg);
        }

        &__top-left:before {
            left: 5%;
        }

        &__top-right:before {
            right: 5%;
        }

        /* BOTTOM LEFT/RIGHT */
        &__bottom-left:before,
        &__bottom-right:before {
            bottom: calc(var(--default-pixel) * -1);
        }

        &__bottom-left:before {
            left: 5%;
        }

        &__bottom-right:before {
            right: 5%;
        }

        &__bottom-left:before {
            left: 5%;
        }
    }

    &__suggestion {
        font-size: .875rem;
        margin: 0 0 1em !important;
        text-align: center;
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        &__item {
            font-size: 1.125rem;
            display: inline-block;
            padding: 0 1.5em;
            color: var(--zumba-gray-400);
            border-right: 2px solid var(--zumba-gray-800);

            &:last-of-type {
                border: none;
            }
        }
    }

    &__met-requirement {
        color: var(--zumba-white);
    }
}
</style>
