<template>
    <ZButton
        rounded="always"
        variety="cyclops"
        super-compact="never"
        compact="never"
        wide="always"
        class="apple-button"
        @click.prevent="emit('click')"
    >
        <template #icon>
            <AppleSVG class="apple-svg" />
        </template>
    </ZButton>
</template>

<script setup lang="ts">
import ZButton from '@components/Core/ZButton.vue';
import AppleSVG from '@icons/social/apple.svg';

const emit = defineEmits(['click'])

</script>

<style scoped>

.apple-button:hover .apple-svg :deep(.apple_svg__pathColorFill) {
    fill: var(--zumba-white)
}
</style>
